import React, { FunctionComponent } from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import ServiceNavCta from "../../cta/ServiceNavCta";
import { useUseCaseI18nLink } from "../../../hooks/useI18nLink";
import { useUseCases } from "../../../hooks/useUseCases";

const UseCaseNav: FunctionComponent = () => {
  const { language } = useI18next();

  const { useCaseLink } = useUseCaseI18nLink();
  const useCase = useUseCases();

  return (
    <section className="container">
      <ul className="px-6 md:px-12 pt-2 md:py-8 space-y-2">
        {useCase.map((useCase) => (
          <li className="text-gray-700 hover:text-gray-900">
            <Link key={useCase.id} to={useCaseLink(useCase)}>
              {useCase.hero.projectName[language]}
            </Link>
          </li>
        ))}
      </ul>
      <ServiceNavCta />
    </section>
  );
};

export default UseCaseNav;
