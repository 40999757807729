import React, { useEffect } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { usePushGtmEvent } from "../hooks/useGmtPushEvent";
import { useLocation, useMatch } from "@reach/router";
import { useI18nLink, useServiceI18nLink } from "../hooks/useI18nLink";

const languageName = {
  it: "It",
  en: "En",
};

type LanguageProps = {
  dark?: boolean;
};

const Language = (props: LanguageProps) => {
  const { changeLanguage, language: currentLanguage } = useI18next();
  const route = useLocation();
  const matchBlog = useMatch("/blog/*");
  const matchResources = useMatch("/risorse/*");
  const matchPrivacy = useMatch("/privacy/*");
  const pushGtmEvent = usePushGtmEvent();
  const i18nLink = useI18nLink();

  useEffect(() => pushGtmEvent({ language: currentLanguage }), []);

  if (matchBlog || matchResources) return null;

  return (
    <div>
      {Object.keys(languageName).map((language, i) => (
        <button
          key={language}
          onClick={() =>
            changeLanguage(
              language,
              i18nLink(route.pathname, language, Boolean(matchPrivacy)),
            )
          }
          className={`font-mono text-sm uppercase px-2
                ${
                  currentLanguage === language
                    ? "text-primary"
                    : props.dark
                      ? "text-gray-300 cursor-pointer"
                      : "text-gray-700 cursor-pointer"
                }
                ${i > 0 ? "border-l border-gray-300" : ""}
              `}
        >
          {languageName[language]}
        </button>
      ))}
    </div>
  );
};

export default Language;
