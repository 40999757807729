import { useStaticQuery, graphql } from "gatsby";
import { useMemo } from "react";

export type UseCasePreview = {
  id: string;
  slug: string;
  enSlug: string;
  hero: {
    projectName: {
      it: string;
      en: string;
    };
    subtitle: {
      en: string;
      it: string;
    };
    services: string[];
  };
};

export const useUseCases = () => {
  const { useCase } = useStaticQuery<{
    useCase: {
      nodes: UseCasePreview[];
    };
  }>(graphql`
    query {
      useCase: allUseCaseJson(sort: { fields: order }) {
        nodes {
          id
          slug
          enSlug
          hero {
            projectName {
              it
              en
            }
            subtitle {
              en
              it
            }
            services
          }
        }
      }
    }
  `);

  return useCase.nodes;
};

export const useServiceUseCases = (id: string) => {
  const useCases = useUseCases();

  return useMemo(
    () => useCases.filter((useCase) => useCase.hero.services.includes(id)),
    [useCases, id],
  );
};
