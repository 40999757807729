import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useAbsoluteUrl } from "../hooks/useAbsoluteUrl";
import { useLocaleCode } from "../hooks/useLocaleCode";
import { useConfig } from "../hooks/useConfig";

const NoIndexSEO = () => (
  <Helmet>
    <meta name="robots" content="noindex" />
  </Helmet>
);

type IndexSeoProps = (
  | { titleI18nKey: string; title?: never }
  | { titleI18nKey?: never; title: string }
) &
  (
    | { descriptionI18nKey: string; description?: never }
    | { descriptionI18nKey?: never; description: string }
  ) & {
    nameI18nKey?: string;
    image?: string;
    datePublished: Date;
    dateModified?: Date;

    article?: {
      headline: string;
      category: string;
      keywords: string[];
    };

    service?: {
      audience: string;
      description: string;
      name: string;
      category: string;
    };

    jobPositions?: {
      seniority: string;
      title: string;
      description: string;
      salary: [number, number];
    }[];
  };

const IndexSEO: FunctionComponent<IndexSeoProps> = (props) => {
  const config = useConfig();
  const { t } = useI18next();
  const locale = useLocaleCode();
  const { buildAbsoluteUrl, buildAbsoluteUrlWithTrailingSlash } =
    useAbsoluteUrl();
  const { pathname } = useLocation();
  const { company, linkedin, glassdoor } = useStaticQuery(graphql`
    query {
      company: companyJson {
        name
      }
      linkedin: socialJson(jsonId: { eq: "linkedin" }) {
        url
      }
      glassdoor: socialJson(jsonId: { eq: "glassdoor" }) {
        url
      }
    }
  `);

  const {
    titleI18nKey,
    descriptionI18nKey,
    title = t(titleI18nKey!),
    description = t(descriptionI18nKey!),
    image,
    datePublished,
    dateModified = datePublished,
    article,
    service,
    jobPositions,
  } = props;

  const imageUrl = image ? buildAbsoluteUrl(image) : "";
  const pageUrl = buildAbsoluteUrlWithTrailingSlash(pathname);
  const logoUrl = buildAbsoluteUrl(
    require("../assets/images/logo.png").default,
  );

  const schema: any = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": buildAbsoluteUrl("/#organization"),
        name: company.name,
        url: buildAbsoluteUrl("/"),
        sameAs: [linkedin.url, glassdoor.url],
        logo: {
          "@type": "ImageObject",
          "@id": buildAbsoluteUrl("/#logo"),
          inLanguage: locale,
          url: logoUrl,
          contentUrl: logoUrl,
          width: 500,
          height: 394,
          caption: company.name,
        },
        image: {
          "@id": buildAbsoluteUrl("/#logo"),
        },
      },
      {
        "@type": "WebSite",
        "@id": buildAbsoluteUrl("/#website"),
        url: buildAbsoluteUrl("/"),
        name: "Astrorei",
        description: t("common:meta.schema.websiteDescription"),
        publisher: {
          "@id": buildAbsoluteUrl("/#organization"),
        },
        inLanguage: locale,
      },
      {
        "@type": "WebPage",
        "@id": buildAbsoluteUrl(pathname, "/#webpage"),
        url: pageUrl,
        name: title,
        isPartOf: {
          "@id": buildAbsoluteUrl("/#website"),
        },
        datePublished: datePublished.toISOString(),
        dateModified: dateModified.toISOString(),
        description: description,
        inLanguage: locale,
      },
    ],
  };

  if (article) {
    schema["@graph"].push({
      "@type": "Article",
      "@id": buildAbsoluteUrl(pathname, "#article"),
      isPartOf: {
        "@id": buildAbsoluteUrl(pathname, "#webpage"),
      },
      headline: article.headline,
      dateModified: dateModified.toISOString(),
      mainEntityOfPage: {
        "@id": buildAbsoluteUrl(pathname, "#webpage"),
      },
      publisher: {
        "@id": buildAbsoluteUrl("/#organization"),
      },
      image: {
        "@id": buildAbsoluteUrl(pathname, "#primaryimage"),
      },
      thumbnailUrl: imageUrl,
      keywords: article.keywords,
      articleSection: article.category,
      inLanguage: locale,
    });
  }

  if (service) {
    schema["@graph"].push({
      "@type": "Service",
      areaServed: "Italy",
      url: pageUrl,
      audience: service.audience,
      description: service.description,
      name: service.name,
      category: service.category,
    });
  }

  if (jobPositions) {
    schema["@graph"].push(
      jobPositions.map((job) => ({
        "@context": "https://schema.org/",
        "@type": "JobPosting",

        title: `${job.seniority} -  ${job.title}`,
        description: job.description,

        employmentType: "FULL_TIME",
        hiringOrganization: {
          "@type": "Organization",
          name: company.name,
          sameAs: buildAbsoluteUrl("/"),
          logo: logoUrl,
        },
        jobLocationType: "TELECOMMUTE",
        directApply: true,
        applicantLocationRequirements: {
          "@type": "Country",
          name: "Italy",
        },
        baseSalary: {
          "@type": "MonetaryAmount",
          currency: "EUR",
          value: {
            "@type": "QuantitativeValue",
            minValue: job.salary[0],
            maxValue: job.salary[1],
            unitText: "YEAR",
          },
        },
      })),
    );
  }

  return (
    <Helmet>
      {/*  Primary Meta Tags */}
      <title>{title}</title>
      <meta
        name="google-site-verification"
        content="_Lezxsx8Mik7D9LQsOySMWet8qHeH69_xNJY76KGLgY"
      />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta
        property="article:published_time"
        content={datePublished.toLocaleDateString("eu")}
      />
      <link rel="canonical" href={pageUrl} />
      {/* Open Graph / Facebook */}
      <meta property="og:site_name" content={t("common:meta.siteName")} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={pageUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
      {/* Schema.org */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      {/* Indexing */}
      <meta
        name="robots"
        content={
          config.seo.index
            ? "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
            : "noindex"
        }
      />
    </Helmet>
  );
};

type SEOProps =
  | ({ noindex?: false } & IndexSeoProps)
  | {
      noindex: true;
    };

const SEO: FunctionComponent<SEOProps> = (props) => {
  return props.noindex === true ? <NoIndexSEO /> : <IndexSEO {...props} />;
};

export default SEO;
