import { useStaticQuery, graphql } from "gatsby";

export const useServiceTree = () => {
  const { allServicesJson, allSolutionsJson } = useStaticQuery<{
    allServicesJson: {
      nodes: {
        id: string;
        icon: string;
        slug: string;
        enSlug: string;
      }[];
    };
    allSolutionsJson: {
      nodes: {
        id: string;
        serviceId: string;
        slug: string;
        enSlug: string;
        icon: string;
      }[];
    };
  }>(graphql`
    query ServiceTreeQuery {
      allServicesJson(sort: { fields: order }) {
        nodes {
          id: jsonId
          icon
          slug
          enSlug
        }
      }
      allSolutionsJson(sort: { fields: order }) {
        nodes {
          id: jsonId
          serviceId
          slug
          enSlug
          icon
        }
      }
    }
  `);

  const solutionsMap = allSolutionsJson.nodes.reduce(
    (acc, curr) => {
      if (!acc[curr.serviceId]) acc[curr.serviceId] = [];
      acc[curr.serviceId].push(curr);
      return acc;
    },
    {} as Record<string, (typeof allSolutionsJson)["nodes"]>,
  );
  const services = allServicesJson.nodes.map((service) => ({
    ...service,
    solutions: solutionsMap[service.id],
  }));

  return services;
};
